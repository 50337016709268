export default {
  // Message structure follows Vue components hierarchy
  en: {
    changePassword: {
      back: 'Back',
      changePassword: 'Change Password',
      confirmPassword: 'Confirm Password',
      currentPassword: 'Current Password',
      newPassword: 'New Password',
      username: 'Username'
    },
    login: {
      backToLogin: 'Back to Login',
      buttonLabel: 'Log In',
      changePassword: 'Change Password',
      confirmPassword: 'Confirm Password',
      forgotPassword: 'Forgot Password',
      forgotPasswordQuestion: 'Forgot Password?',
      forgotPasswordUsernameNotFound: 'Username does not exist.',
      incorrectUsernameOrPassword: 'Incorrect username or password.',
      invalidUsername: 'The username includes not allowed characters, like spaces.',
      missingCognitoGroupMembership: 'The user must be a member of at least one Cognito group',
      password: 'Password',
      passwordChangedSuccessfully: 'Password changed successfully.',
      passwordResetSuccessfully: 'Password reset successfully.',
      passwordRuleLengthError: 'Password must be at least 8 characters long.',
      passwordRuleLowercaseError: 'Password must contain at least one lowercase character.',
      passwordRuleNumberError: 'Password must contain at least one number.',
      passwordRules: {
        lengthRule: 'Contains at least 8 characters.',
        lowercaseRule: 'Contains a lowercase character.',
        numberRule: 'Contains a numeric character.',
        specialCharacterRule: 'Contains a special character.',
        uppercaseRule: 'Contains an uppercase character.'
      },
      passwordRuleSpecialCharacterError: 'Password must contain at least one special character.',
      passwordRuleUppercaseError: 'Password must contain at least one uppercase character.',
      passwordsDoNotMatch: 'Passwords do not match.',
      passwordsMustMatch: 'Passwords must match.',
      resetPassword: 'Reset Password',
      send: 'Send',
      title: 'Welcome',
      username: 'Username',
      verificationCode: 'Verification Code'
    }
  }
}

<template>
  <div>
    <b-navbar
      type="dark"
      variant="primary"
    >
      <b-navbar-brand
        class="d-flex"
      >
        <span
          class="mdi mdi-2rem"
          :class="`mdi-${config.application.icon}`"
        />
        <span class="text-application-name ml-2">{{ config.application.name }}</span>
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown
          right
          no-caret
          :class="{ 'invisible': !loggedInUser }"
        >
          <template slot="button-content">
            <span class="mdi mdi-menu mdi-2rem text-white align-middle" />
          </template>
          <template v-if="loggedInUser">
            <b-dropdown-text class="text-menu-username">
              {{ loggedInUser.username }}
            </b-dropdown-text>
            <b-dropdown-divider class="mx-4" />
            <b-dropdown-item @click="logout">
              Logout
            </b-dropdown-item>
          </template>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import { LOGOUT } from '@/store/auth/action.types'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['config', 'loggedInUser'])
  },

  methods: {
    logout () {
      this.$store.dispatch(LOGOUT)
    }
  }
}
</script>

import * as actionTypes from './action.types'
import { GET_DOCUMENT_CONTENT, GET_SIGNED_GET_URL } from '@/store/api/action.types'

const actions = {
  async [actionTypes.DOWNLOAD_DOCUMENT] ({ dispatch }, { bucket, key }) {
    const signedUrl = await dispatch(GET_SIGNED_GET_URL, { Bucket: bucket, Key: key })
    const documentContent = await dispatch(GET_DOCUMENT_CONTENT, { url: signedUrl.url })
    return documentContent
  },

  [actionTypes.SAVE_DOCUMENT] (store, { objectUrl, name }) {
    const link = document.createElement('a')
    link.setAttribute('href', objectUrl)
    link.setAttribute('download', name)
    link.dispatchEvent(new MouseEvent('click', { cancelable: true }))
    link.remove()
  },

  async [actionTypes.DOWNLOAD_AND_SAVE_DOCUMENT] ({ dispatch }, { bucket, key, name }) {
    const documentContent = await dispatch(actionTypes.DOWNLOAD_DOCUMENT, { bucket, key })

    const objectUrl = URL.createObjectURL(documentContent)

    dispatch(actionTypes.SAVE_DOCUMENT, { objectUrl, name })

    URL.revokeObjectURL(objectUrl)
  }
}

export default {
  actions
}

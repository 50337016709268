const classification = [
  { id: 1, description: 'Invasive Device?', yes: '2', no: '3', rule_yes: '', rule_no: '', note: 'Invasive devices are devices which are introduced into the patient’s body by means of either through an opening in the body, so-called body orifice, or through a rupture or break of the skin.', result_description_yes: '', result_description_no: '' },
  { id: 2, description: 'Contact with Injured skin or mucous membrane?', yes: '4', no: '5', rule_yes: '', rule_no: '', note: 'Injured skin or mucus membrane means an area of skin or a mucus membrane presenting a pathological change or change following disease or a wound', result_description_yes: '', result_description_no: '' },
  { id: 3, description: 'Channelling or storing blood, body liquids or tissues or gases for the purpose of eventual infusion, administration or introduction into the body?', yes: '6', no: '9', rule_yes: '', rule_no: '', note: '', result_description_yes: '', result_description_no: '' },
  { id: 4, description: 'Intended to be used as a mechanical barrier, for compression or for absorption of exudates?', yes: 'Class I', no: '10', rule_yes: 'Rule 4', rule_no: 'Rule 4', note: '', result_description_yes: '%product% is intended to be used as a mechanical barrier, for compression or for absorption of exudates. Therefore, the product is classified as Class I device.', result_description_no: '' },
  { id: 5, description: 'Intended for body orifices, other than surgically invasive devices, which are not intended for connection to an active device or which are intended for connection to a class I active device?', yes: '11', no: '18', rule_yes: 'Rule 5', rule_no: 'Rule 5', note: 'Body orifice means any natural opening in the body, as well as the external surface of the eyeball, or any permanent artificial opening, such as a stoma', result_description_yes: '', result_description_no: '' },
  { id: 6, description: 'Connected to a class II a, II b, III?', yes: 'Class  IIa', no: '7', rule_yes: 'Rule 2', rule_no: 'Rule 2', note: '', result_description_yes: '%product% is connected to a class IIa, class IIb or class III device. Therefore, %product% is classified as a Class IIa device.', result_description_no: '' },
  { id: 7, description: 'Channelling or storing blood, other body liquids or storing organs, parts of organs, body cells or tissue?', yes: '8', no: 'Class I', rule_yes: 'Rule 2', rule_no: 'Rule 2', note: '', result_description_yes: '', result_description_no: '%product% has no additional intend. Therefore, %product% is classified as Class I device.' },
  { id: 8, description: 'Blood bag?', yes: 'Class IIb', no: 'Class  IIa', rule_yes: 'Rule 2', rule_no: 'Rule 2', note: '', result_description_yes: '%product% is a blood bag and therefore, %product% is classified as Class IIb device', result_description_no: '%product% is intended for use for channelling or storing blood or other body liquids or for storing organs, parts of organs or body cells and tissues, except for blood bags. Therefore, %product% is classified as a Class IIa device.' },
  { id: 9, description: 'Modifying the biological or chemical composition of human tissues or cells, blood, other body liquids or other liquids intended for implantation or administration into the body?', yes: '12', no: '13', rule_yes: '', rule_no: '', note: '', result_description_yes: '', result_description_no: '' },
  { id: 10, description: 'Intended to be used principally for injuries to skin which have breached the dermis or mucous membrane and can only heal by secondary intent?', yes: 'Class IIb', no: '17', rule_yes: 'Rule 4', rule_no: 'Rule 4', note: '', result_description_yes: '%product% is intended  to be  used  principally for  injuries  to skin which have  breached the  dermis  or mucous membrane and can only heal by secondary intent. Therefore, the product is classified as Class IIb device.', result_description_no: '' },
  { id: 11, description: 'Transient use?', yes: 'Class I', no: '14', rule_yes: 'Rule 5', rule_no: 'Rule 5', note: 'Transient means normally intended for continuous use for less than 60 minutes.', result_description_yes: '%product% is intended for transient use. Therefore, %product% is classified as Class I device.', result_description_no: '' },
  { id: 12, description: 'Treatment consists of filtration, centrifugation or exchanges Gas or heat.', yes: 'Class IIa', no: 'Class IIb', rule_yes: 'Rule 3', rule_no: 'Rule 3', note: '', result_description_yes: '%product% is used for the treatment for which the device is used consists of filtration, centrifugation or exchanges of gas, heat. Therefore, %product% is classified as Class IIa device.', result_description_no: '%product% has no additional intend. Therefore, %product% is classified as Class IIb device.' },
  { id: 13, description: 'Contact with Injured skin or mucous membrane?', yes: '4', no: 'Class I', rule_yes: '', rule_no: 'Rule 1', note: '', result_description_yes: '', result_description_no: '%product% is an non-invasive device. Therefore, %product% is classified as a Class I device.' },
  { id: 14, description: 'Short-term use?', yes: '15', no: '16', rule_yes: 'Rule 5', rule_no: 'Rule 5', note: 'Short term means normally intended for continuous use for between 60 minutes and 30 days', result_description_yes: '', result_description_no: '' },
  { id: 15, description: 'Used in the oral cavity as far as the pharynx, in an ear canal up to the ear drum or in the nasal cavity?', yes: 'Class I', no: 'Class IIa', rule_yes: 'Rule 5', rule_no: 'Rule 5', note: '', result_description_yes: '%product% is intended for short-term use, except if it is used in the oral cavity as far as the pharynx, in an ear canal up to the ear drum or in the nasal cavity. Therefore, %product% is classified as Class I device.', result_description_no: '%product% is intended for short-term use. Therefore, %product% is classified as Class IIa device.' },
  { id: 16, description: 'Used in the oral cavity as far as the pharynx, in an ear canal up to the ear drum or in the nasal cavity and are not liable to be absorbed by the mucous membrane?', yes: 'Class IIa', no: 'Class IIb', rule_yes: 'Rule 5', rule_no: 'Rule 5', note: '', result_description_yes: '%product% is intended for use in the oral cavity as far as the pharynx, in an ear canal up to the ear drum or  in the nasal cavity and are not liable to be absorbed by the mucous membrane. Therefore, %product% is classified as Class IIa device.', result_description_no: '%product% is intended for long-term use, except if it is used in the oral cavity as far as the pharynx, in an ear canal up to the ear drum or  in the nasal cavity and are not liable to be absorbed by the mucous membrane. Therefore, %product% is classified as Class IIb device.' },
  { id: 17, description: 'Principally intended to manage the micro-environment of injured skin or mucous Membrane?', yes: 'Class IIa', no: 'Class IIa', rule_yes: 'Rule 4', rule_no: 'Rule 4', note: '', result_description_yes: '%product% is principally intended  to  manage  the  micro-environment of  injured skin  or  mucous membrane Therefore, the product is classified as Class IIa device.', result_description_no: '%product% has no additional intend. Therefore, %product% is classified as Class IIa device.' },
  { id: 18, description: 'Invasive devices with respect to body orifices, other than surgically invasive devices, intended for connection to a class IIa, class llb or class lll active device? ', yes: 'Class lla', no: '19', rule_yes: 'Rule 5', rule_no: 'Rule 5', note: '', result_description_yes: '%product% is an invasive device with respect to body orifices, other than surgically invasive devices, intended for connection to a class IIa, class IIb or class III active device. Therefore, %product% is classified as Class IIa device.', result_description_no: '' },
  { id: 19, description: 'Surgically invasive devices intended for transient use?', yes: '20', no: '21', rule_yes: '', rule_no: '', note: '', result_description_yes: '', result_description_no: '' },
  { id: 20, description: 'Intended specifically to control, diagnose, monitor or correct a defect of the heart or of the central circulatory system through direct contact with those parts of the body?', yes: 'Class lll', no: '20', rule_yes: 'Rule 6', rule_no: 'Rule 6', note: '', result_description_yes: '%product% is intended  specifically  to  control, diagnose, monitor or  correct  a  defect of  the  heart  or  of  the  central circulatory system through direct  contact with  those parts  of  the body. Therefore, %product% is classified as Class III device.', result_description_no: '' },
  { id: 21, description: 'Surgically invasive devices intended for short-term use?', yes: '27', no: '33', rule_yes: '', rule_no: '', note: '', result_description_yes: '', result_description_no: '' },
  { id: 22, description: 'Reusable surgical instruments?', yes: 'Class l', no: '23', rule_yes: 'Rule 6', rule_no: 'Rule 6', note: '', result_description_yes: '%product% is a reusable surgical instrument. Therefore, %product% is classified as Class I device.', result_description_no: '' },
  { id: 23, description: 'Intended specifically for use in direct contact with the heart or central circulatory system or the central nervous system?', yes: 'Class lll', no: '24', rule_yes: 'Rule 6', rule_no: 'Rule 6', note: '', result_description_yes: '%product% is intended specifically for  use in direct contact with the heart or central circulatory system or  the central nervous system. Therefore, %product% is classified as Class III device.', result_description_no: '' },
  { id: 24, description: 'Intended to supply energy in the form of ionising radiation?', yes: 'Class llb', no: '25', rule_yes: 'Rule 6', rule_no: 'Rule 6', note: '', result_description_yes: '%product% is intended to supply energy in the form of ionising radiation. Therefore, %product% is classified as Class IIb device.', result_description_no: '' },
  { id: 25, description: 'Biological effect or are wholly or mainly absorbed?', yes: 'Class llb', no: '26', rule_yes: 'Rule 6', rule_no: 'Rule 6', note: '', result_description_yes: '%product% has a biological effect or is wholly or mainly absorbed. Therefore, %product% is classified as Class IIb device.', result_description_no: '' },
  { id: 26, description: 'Intended to administer medicinal products by means of a delivery system, if such administration of a medicinal product is done in a manner that is potentially hazardous taking account of the mode of application?', yes: 'Class llb', no: 'Class lla', rule_yes: 'Rule 6', rule_no: 'Rule 6', note: '', result_description_yes: '%product% is intended to  administer  medicinal products by  means of  a  delivery  system, if  such  administration of a  medicinal product is  done  in  a  manner that  is  potentially  hazardous taking account of  the  mode of application. Therefore, %product% is classified as Class IIb device.', result_description_no: '%product% has no additional intend. Therefore, %product% is classified as Class IIa device.' },
  { id: 27, description: 'Intended specifically to control, diagnose, monitor or correct a defect of the heart or of the central Circulatory system through direct contact with those parts of the body?', yes: 'Class lll', no: '28', rule_yes: 'Rule 7', rule_no: 'Rule 7', note: '', result_description_yes: '%product% is intended  specifically  to  control, diagnose, monitor or  correct  a  defect of  the  heart  or  of  the  central circulatory system through direct  contact with  those parts  of  the body. Therefore, %product% is classified as Class III device.', result_description_no: '' },
  { id: 28, description: 'Intended specifically for use in direct contact with the heart or central circulatory system or the central nervous system?', yes: 'Class lll', no: '29', rule_yes: 'Rule 7', rule_no: 'Rule 7', note: '', result_description_yes: '%product% is intended specifically for  use in direct contact with the heart or central circulatory system or  the central nervous system. Therefore, %product% is classified as Class III device.', result_description_no: '' },
  { id: 29, description: 'Intended to supply energy in the form of ionizing radiation?', yes: 'Class llb', no: '30', rule_yes: 'Rule 7', rule_no: 'Rule 7', note: '', result_description_yes: '%product% is intended to supply energy in the form of ionizing radiation . Therefore, %product% is classified as Class IIb device.', result_description_no: '' },
  { id: 30, description: 'Biological effect or are wholly or mainly absorbed?', yes: 'Class lll', no: '31', rule_yes: 'Rule 7', rule_no: 'Rule 7', note: '', result_description_yes: '%product% has a biological effect or is wholly or mainly absorbed. Therefore, %product% is classified as Class III device.', result_description_no: '' },
  { id: 31, description: 'Intended to undergo chemical change in the body other than placed on the teeth?', yes: 'Class llb', no: '32', rule_yes: 'Rule 7', rule_no: 'Rule 7', note: '', result_description_yes: '%product% is intended to undergo chemical change in the body, except if placed in the teeth. Therefore, %product% is classified as Class IIb device.', result_description_no: '' },
  { id: 32, description: 'Intended to administer medicines?', yes: 'Class llb', no: 'Class lla', rule_yes: 'Rule 7', rule_no: 'Rule 7', note: '', result_description_yes: '%product% is intended to administer medicines. Therefore, %product% is classified as Class IIb device.', result_description_no: '%product% has no additional intend. Therefore, %product% is classified as Class IIa device.' },
  { id: 33, description: 'Implantable devices and long-term surgically invasive devices?', yes: '34', no: '49', rule_yes: '', rule_no: '', note: '', result_description_yes: '', result_description_no: '' },
  { id: 34, description: 'Intended to be placed in the teeth?', yes: 'Class lla', no: '35', rule_yes: 'Rule 8', rule_no: 'Rule 8', note: '', result_description_yes: '%product% is intended to be placed in the teeth. Therefore, %product% is classified as Class IIa device.', result_description_no: '' },
  { id: 35, description: 'Intended to be used in direct contact with the heart, the central circulatory system or the central nervous system?', yes: 'Class lll', no: '36', rule_yes: 'Rule 8', rule_no: 'Rule 8', note: '', result_description_yes: '%product% is intended to be used in direct contact with the heart, the central circulatory system or the central nervous system. Therefore, %product% is classified as Class III device.', result_description_no: '' },
  { id: 36, description: 'Biological effect or are wholly or mainly absorbed?', yes: 'Class lll', no: '37', rule_yes: 'Rule 8', rule_no: 'Rule 8', note: '', result_description_yes: '%product% has a biological effect or is wholly or mainly absorbed. Therefore, %product% is classified as Class III device.', result_description_no: '' },
  { id: 37, description: 'Intended to undergo chemical change in the body and ir not placed in the teeth?', yes: 'Class lll', no: '38', rule_yes: 'Rule 8', rule_no: 'Rule 8', note: '', result_description_yes: '%product% is intended to undergo chemical change in the body, except if placed in the teeth. Therefore, %product% is classified as Class III device.', result_description_no: '' },
  { id: 38, description: 'Intended to administer medicinal products?', yes: 'Class lll', no: '39', rule_yes: 'Rule 8', rule_no: 'Rule 8', note: '', result_description_yes: '%product% is intended to administer medicines. Therefore, %product% is classified as Class III device.', result_description_no: '' },
  { id: 39, description: 'Active implantable devices or their accessories?', yes: 'Class lll', no: '40', rule_yes: 'Rule 8', rule_no: 'Rule 8', note: '', result_description_yes: '%product% is an active implantable device or their accessories. Therefore, %product% is classified as Class III device.', result_description_no: '' },
  { id: 40, description: 'Breast implants or surgical meshes?', yes: 'Class lll', no: '41', rule_yes: 'Rule 8', rule_no: 'Rule 8', note: '', result_description_yes: '%product% is a breast implant or surgical mesh. Therefore, %product% is classified as Class III device.', result_description_no: '' },
  { id: 41, description: 'Total or partial joint replacements but not ancillary components such as screws, wedges, plates and instruments?', yes: 'Class lll', no: '42', rule_yes: 'Rule 8', rule_no: 'Rule 8', note: '', result_description_yes: '%product% is a total  or  partial  joint  replacement, with exception of ancillary components such as screws, wedges, plates and instruments. Therefore, %product% is classified as Class III device.', result_description_no: '' },
  { id: 42, description: 'Spinal disc replacement implants or are implantable devices that come into contact with the spinal column but not components such as screws, wedges, plates and instruments?', yes: 'Class lll', no: 'Class llb', rule_yes: 'Rule 8', rule_no: 'Rule 8', note: '', result_description_yes: '%product% is a spinal disc  replacement implant or is an implantable device that  comes into  contact with  the  spinal column, with exception of ancillary components such as screws, wedges, plates and instruments. Therefore, %product% is classified as Class III device.', result_description_no: '' },
  { id: 44, description: 'Administer energy to or exchange energy with the human body? ', yes: '45', no: 'Class I', rule_yes: 'Rule 9', rule_no: 'Rule 13', note: '', result_description_yes: '%product% is an active therapeutic device intended  to  administer  or  exchange  energy with the characteristics  that  it  may  administer energy to  or  exchange energy with  the  human body  in a  potentially  hazardous way, taking account of  the nature, the  density and  site  of application of  the energy. Therefore, %product% is classified as Class IIb device.', result_description_no: '' },
  { id: 45, description: 'In a potentially hazardous way, taking account of the nature, the density and site of application of the energy?', yes: 'Class IIb', no: '46', rule_yes: 'Rule 9', rule_no: 'Rule 9', note: '', result_description_yes: '', result_description_no: '' },
  { id: 46, description: 'Intended to control or monitor the performance of active therapeutic class IIb devices, or intended directly to influence the performance of such devices?', yes: 'Class llb', no: '47', rule_yes: 'Rule 9', rule_no: 'Rule 9', note: '', result_description_yes: '%product% is an active device intended to  control or  monitor the  performance  of  active therapeutic. Therefore, %product% is classified as Class IIb device.', result_description_no: '' },
  { id: 47, description: 'Intended to emit ionizing radiation for therapeutic purposes, including devices which control or monitor such devices, or which directly influence their performance?', yes: 'Class llb', no: '48', rule_yes: 'Rule 9', rule_no: 'Rule 9', note: '', result_description_yes: '%product% is an active device intended to emit ionizing radiation for therapeutic purposes, including devices which control or monitor such devices, or which directly influence their performance. Therefore, %product% is classified as Class IIb device.', result_description_no: '' },
  { id: 48, description: 'Intended for controlling, monitoring or directly influencing the performance of active implantable devices?', yes: 'Class lll', no: 'Class lla', rule_yes: 'Rule 9', rule_no: 'Rule 9', note: '', result_description_yes: '%product% is an active device intended for controlling, monitoring or directly influencing the performance of active implantable devices. Therefore, %product% is classified as Class III device.', result_description_no: '%product% is an active therapeutic device intended  to  administer  or  exchange  energy, unless their characteristics  are  such  that  they  may  administer energy to  or  exchange energy with  the  human body  in a  potentially  hazardous way, taking account of  the nature, the  density and  site  of application of  the energy. Therefore, %product% is classified as Class IIa device.' },
  { id: 49, description: 'Active devices intended for diagnosis and monitoring?', yes: '50', no: '54', rule_yes: '', rule_no: '', note: '', result_description_yes: '', result_description_no: '' },
  { id: 50, description: "Intended to supply energy which will be absorbed by the human body with the intend to illuminate the patient's body, in the visible spectrum?", yes: 'Class l', no: '51', rule_yes: 'Rule 10', rule_no: 'Rule 10', note: '', result_description_yes: "%product% is an active device intended to supply energy which will be absorbed by the human body, except for devices intended to illuminate the patient's body, in the visible spectrum. Therefore, %product% is classified as Class I device.", result_description_no: '' },
  { id: 51, description: 'Intended to image in vivo distribution of radiopharmaceuticals?', yes: 'Class llb', no: '52', rule_yes: 'Rule 10', rule_no: 'Rule 10', note: '', result_description_yes: '%product% is an active device intended to image in vivo distribution of radiopharmaceuticals. Therefore, %product% is classified as Class IIb device.', result_description_no: '' },
  { id: 52, description: 'Intended to allow direct diagnosis or monitoring of vital physiological processes AND specifically intended for monitoring of vital physiological parameters and the nature of variations of those parameters is such that it could result in immediate danger to the patient, for instance variations in cardiac performance, respiration, activity of the central nervous system, or they are intended for diagnosis in clinical situations where the patient is in immediate danger?', yes: 'Class llb', no: '53', rule_yes: 'Rule 10', rule_no: 'Rule 10', note: '', result_description_yes: '%product% is an active device intended to allow direct diagnosis or  monitoring of vital physiological processes, unless they are specifically  intended  for  monitoring  of  vital  physiological parameters  and  the  nature of  variations of  those parameters is such that it could result in immediate danger  to the patient, for  instance variations in cardiac performance, respiration, activity of the central nervous system, or they are intended for diagnosis in clinical situations where the patient is in immediate danger. Therefore, %product% is classified as Class IIb device.', result_description_no: '' },
  { id: 53, description: 'Intended to emit ionizing radiation and intended for diagnostic or therapeutic radiology, including interventional radiology devices and devices which control or monitor such devices, or which directly influence their performance?', yes: 'Class llb', no: 'Class lla', rule_yes: 'Rule 10', rule_no: 'Rule 10', note: '', result_description_yes: '%product% is an active device intended to emit ionizing radiation and intended for diagnostic or therapeutic radiology, including interventional radiology devices and devices which control or monitor such devices, or  which directly influence their performance. Therefore, %product% is classified as Class IIb device.', result_description_no: '%product% has no additional intend. Therefore, %product% is classified as Class IIa device.' },
  { id: 54, description: 'Software?', yes: '55', no: '60', rule_yes: '', rule_no: '', note: '', result_description_yes: '', result_description_no: '' },
  { id: 55, description: "Intended to provide information which is used to take decisions with diagnosis or therapeutic purposes with impact that may cause death or an irreversible deterioration of a person's state of health?", yes: 'Class lll', no: '56', rule_yes: 'Rule 11', rule_no: 'Rule 11', note: '', result_description_yes: "%product% is software which decision may cause death or an irreversible deterioration of a person's state of health. Therefore, %product% is classified as Class III device.", result_description_no: '' },
  { id: 56, description: "Intended to provide information which is used to take decisions with diagnosis or therapeutic purposes with impact that may cause a serious deterioration of a person's state of health or a surgical intervention?", yes: 'Class llb', no: '57', rule_yes: 'Rule 11', rule_no: 'Rule 11', note: '', result_description_yes: "%product% is software which decision may cause a serious deterioration of a person's state of health or a surgical intervention. Therefore, %product% is classified as Class IIb device.", result_description_no: '' },
  { id: 57, description: 'Intended for monitoring of vital physiological parameters, where the nature of variations of those parameters is such that it could result in immediate danger to the patient?', yes: 'Class llb', no: '58', rule_yes: 'Rule11', rule_no: 'Rule11', note: '', result_description_yes: '%product% is software intended  for monitoring of vital physiological parameters, where the nature of variations of  those parameters is such that it could result in immediate danger to the patient. Therefore, %product% is classified as Class IIb device.', result_description_no: '' },
  { id: 58, description: 'Software intended to monitor physiological processes?', yes: 'Class lla', no: '59', rule_yes: 'Rule 11', rule_no: 'Rule 11', note: '', result_description_yes: '%product% is software intended  to  monitor physiological processes. Therefore, %product% is classified as Class IIa device.', result_description_no: '' },
  { id: 59, description: 'Intended to provide information which is used to take decisions with diagnosis or therapeutic purposes?', yes: 'Class lla', no: 'Class l', rule_yes: 'Rule 11', rule_no: 'Rule 11', note: '', result_description_yes: '%product% is software intended to provide information which is used to take decisions with diagnosis or therapeutic purposes. Therefore, %product% is classified as Class IIa device.', result_description_no: '%product% is other software. Therefore, %product% is classified as Class I device.' },
  { id: 60, description: 'Intended to administer and/or remove medicinal products, body liquids or other substances to or from the body done in a manner that is potentially hazardous, taking account of the nature of the substances involved, of the part of the body concerned and of the mode of application?', yes: 'Class llb', no: '61', rule_yes: 'Rule 12', rule_no: 'Rule 12', note: '', result_description_yes: '%product% is an active device which administers and/or removes such moieties in a manner that is potentially hazardous, taking account of  the  nature of  the  substances involved, of  the  part  of  the  body  concerned  and  of  the  mode of application. Therefore, %product% is classified as Class IIb device.', result_description_no: '' },
  { id: 61, description: 'Intended to administer and/or remove medicinal products, body liquids or other substances to or from the body?', yes: 'Class lla', no: '44', rule_yes: 'Rule 12', rule_no: '', note: '', result_description_yes: '%product% is an other active device with no addtional intend. Therefore, %product% is classified as Class IIa device.', result_description_no: '' }
]

const specialRules = [
  { id: 62, description: 'Incorporating, as an integral part, a substance which, if used separately, can be considered to be a medicinal product including a medicinal product derived from human blood or human plasma, and that has an action ancillary to that of the devices?', yes: 'Class lll', no: '63', rule_yes: 'Rule 14', rule_no: '', note: '', result_description_yes: '%product% is an implantable device, which incorporates, as an integral part, the substance %substance_name% which if used separately, may be considered to be a medicinal product. The primary mode of action of the %product% remains fracture treatment, whereas the  %substance_name% provides an ancillary action which is best described by %ancillary_action%. Therefore, %product% is classified as a Class III device.', result_description_no: '' },
  { id: 63, description: 'Used for contraception or prevention of the transmission of sexually transmitted diseases and are implantable or long term invasive devices?', yes: 'Class lll', no: '64', rule_yes: 'Rule 15', rule_no: 'Rule 15', note: '', result_description_yes: '%product% is a device, which is an implantable or long term invasive device. Therefore, %product% is classified as a Class III device.', result_description_no: '' },
  { id: 64, description: 'Used for contraception or prevention of the transmission of sexually transmitted diseases?', yes: 'Class llb', no: '65', rule_yes: 'Rule 15', rule_no: 'Rule 15', note: '', result_description_yes: '%product% is a devices with no addtional intend. Therefore, %product% is classified as Class IIb device.', result_description_no: '' },
  { id: 65, description: 'Intended specifically to be used for disinfecting, cleaning, rinsing or, where appropriate, hydrating contact lenses by physical action only?', yes: 'Class llb', no: '66', rule_yes: 'Rule 16', rule_no: 'Rule 16', note: '', result_description_yes: '%product% is a device, which is intended  specifically  to  be  used  for  disinfecting, cleaning, rinsing or,  where appropriate,  hydrating contact lenses. Therefore, %product% is classified as a Class IIa device.', result_description_no: '' },
  { id: 66, description: 'Intended specifically to be used for disinfecting or sterilising medical devices disinfecting solutions or washer-disinfectors intended specifically to be used for disinfecting invasive devices, as the end point of processing?', yes: 'Class IIb', no: '67', rule_yes: 'Rule 16', rule_no: '', note: '', result_description_yes: '', result_description_no: '' },
  { id: 67, description: 'Specifically intended for recording of diagnostic images generated by X-ray radiation?', yes: 'Class lla', no: '68', rule_yes: 'Rule 17', rule_no: 'Rule 17', note: '', result_description_yes: 'Therefore, %product% is classified as a Class IIa device.', result_description_no: '' },
  { id: 68, description: 'Manufactured utilising tissues or cells of human or animal origin, or their derivatives, which are nonviable or rendered non-viable?', yes: '69', no: '70', rule_yes: '', rule_no: '', note: '', result_description_yes: '', result_description_no: '' },
  { id: 69, description: 'Are manufactured utilising tissues or cells of animal origin, or their derivatives, which are non-viable or rendered non-viable and are devices intended to come into contact with intact skin only?', yes: 'No change in classification', no: 'Class lll', rule_yes: 'Rule 18', rule_no: 'Rule 18', note: '', result_description_yes: '', result_description_no: 'Therefore, %product% is classified as a Class III device.' },
  { id: 70, description: 'Incorporating or consisting of nanomaterial?', yes: '71', no: '73', rule_yes: '', rule_no: '', note: '', result_description_yes: '', result_description_no: '' },
  { id: 71, description: 'Present a high or medium potential for internal exposure?', yes: 'Class lll', no: '72', rule_yes: 'Rule 19', rule_no: 'Rule 19', note: '', result_description_yes: '%product% is a device, which is a device presenting a high or medium potential for internal exposure. Therefore, %product% is classified as a Class III device.', result_description_no: '' },
  { id: 72, description: 'Present a low potential for internal exposure?', yes: 'Class llb', no: 'Class lla', rule_yes: 'Rule 19', rule_no: 'Rule 19', note: '', result_description_yes: '%product% is a device, which is a device presenting a low potential for internal exposure. Therefore, %product% is classified as a Class IIb device.', result_description_no: '%product% is a device, which is a device presenting a negligible potential for internal exposure. Therefore, %product% is classified as a Class IIa device.' },
  { id: 73, description: 'Invasive devices with respect to body orifices, other than surgically invasive devices, which are intended to administer medicinal products by inhalation?', yes: '74', no: '75', rule_yes: '', rule_no: '', note: '', result_description_yes: '', result_description_no: '' },
  { id: 74, description: 'Mode of action has an essential impact on the efficacy and safety of the administered medicinal product or they are intended to treat lifethreatening conditions?', yes: 'Class llb', no: 'Class lla', rule_yes: 'Rule 20', rule_no: 'Rule 20', note: '', result_description_yes: '%product% is a device, which mode of action has an essential impact on  the  efficacy  and  safety of  the  administered  medicinal product or  they  are  intended to  treat  life- threatening conditions. Therefore, %product% is classified as a Class IIb device.', result_description_no: '%product% is a device with no additional intend. Therefore, %product% is classified as a Class IIa device.' },
  { id: 75, description: 'Composed of substances or of combinations of substances that are intended to be introduced into the human body via a body orifice or applied to the skin and that are absorbed by or locally dispersed in the human body?', yes: '76', no: '79', rule_yes: '', rule_no: '', note: '', result_description_yes: '', result_description_no: '' },
  { id: 76, description: 'They, or their products of metabolism, are systemically absorbed by the human body in order to achieve the intended purpose?', yes: 'Class lll', no: '77', rule_yes: 'Rule 21', rule_no: 'Rule 21', note: '', result_description_yes: '%product% is a device which itself or  their  products of metabolism, are systemically absorbed by the human body in order  to achieve the intended purpose. Therefore, %product% is classified as a Class III device.', result_description_no: '' },
  { id: 77, description: 'They achieve their intended purpose in the stomach or lower gastrointestinal tract and they, or their products of metabolism, are systemically absorbed by the human body?', yes: 'Class lll', no: '78', rule_yes: 'Rule 21', rule_no: 'Rule 21', note: '', result_description_yes: '%product% is a device which achieves its intended purpose in the stomach or lower gastrointestinal tract and they, or their products of metabolism, are systemically absorbed by the human body. Therefore, %product% is classified as a Class III device.', result_description_no: '' },
  { id: 78, description: 'They are applied to the skin or if they are applied in the nasal or oral cavity as far as the pharynx, and achieve their intended purpose on those cavities?', yes: 'Class lla', no: 'Class llb', rule_yes: 'Rule 21', rule_no: 'Rule 21', note: '', result_description_yes: '%product% is a device which is applied to the skin or if they are applied in the nasal or oral cavity as far as the pharynx, and achieve their intended purpose on those cavities. Therefore, %product% is classified as a Class IIa device.', result_description_no: '%product% is a device with no additional intend. Therefore, %product% is classified as a Class IIb device.' },
  { id: 79, description: 'Active therapeutic devices with an integrated or incorporated diagnostic function which significantly determines the patient management by the device, such as closed loop systems or automated external defibrillators?', yes: 'Class lll', no: 'No change in classification', rule_yes: 'Rule 22', rule_no: '', note: '', result_description_yes: '%product% is a device with no additional intend. Therefore, %product% is classified as a Class III device.', result_description_no: '' }
]

const classificationRationales = [
  { rule: 'Rule 1', classification_rationale: 'Rule 1 of MDR Annex VIII is covering all non-invasive devices for which no further rule applies.' },
  { rule: 'Rule 2', classification_rationale: 'Rule 2 of MDR Annex VIII is covering all non-invasive devices intended for channelling or storing blood, body liquids, cells or tissues, liquids or gases for the purpose of eventual infusion, administration or introduction into the body.' },
  { rule: 'Rule 3', classification_rationale: 'Rule 3 of MDR Annex VIII is covering all non-invasive devices intended for modifying the biological or chemical composition of human tissues or cells, blood, other body liquids or other liquids intended for implantation or administration into the body.' },
  { rule: 'Rule 4', classification_rationale: 'Rule 4 of MDR Annex VIII is covering all non-invasive devices which come into contact with injured skin or mucous membrane.' },
  { rule: 'Rule 5', classification_rationale: 'Rule 5 of MDR Annex VIII is covering all invasive devices with respect to body orifices, other  than surgically invasive devices, which are not intended for connection to an active device or which are intended for connection to a class I active device.' },
  { rule: 'Rule 6', classification_rationale: 'Rule 6 of MDR Annex VIII is covering all surgically invasive devices intended for transient use.' },
  { rule: 'Rule 7', classification_rationale: 'Rule 7 of MDR Annex VIII is covering all surgically invasive devices intended for short-term use.' },
  { rule: 'Rule 8', classification_rationale: 'Rule 7 of MDR Annex VIII is covering all implantable devices and long-term surgically invasive devices.' },
  { rule: 'Rule 9', classification_rationale: 'Rule 9 of MDR Annex VIII is covering all active devices with a specific intend.' },
  { rule: 'Rule 10', classification_rationale: 'Rule 10 of MDR Annex VIII is covering all active devices intended for diagnosis and monitoring.' },
  { rule: 'Rule 11', classification_rationale: 'Rule 11 of MDR Annex VIII is covering software intended to provide information which is used to take decisions with diagnosis or therapeutic purposes.' },
  { rule: 'Rule 12', classification_rationale: 'Rule 12 of MDR Annex VIII is covering all active devices intended to administer and/or remove medicinal products, body liquids or other substances to or from the body.' },
  { rule: 'Rule 13', classification_rationale: 'Rule 13 of MDR Annex VIII is covering all other active devices.' },
  { rule: 'Rule 14', classification_rationale: 'Rule 14 of MDR Annex VIII is covering all devices incorporating, as an integral part, a substance which, if used  separately, can be considered to be a medicinal product, as definded in Article 1 of Directie 2001/83/EC, and which is liable to act on the human body with action ancillary to that of the devices.' },
  { rule: 'Rule 15', classification_rationale: 'Rule 15 of MDR Annex VIII is covering all devices used for contraception or prevention of the transmission of sexually transmitted diseases.' },
  { rule: 'Rule 16', classification_rationale: 'Rule 16 of MDR Annex VIII is covering all devices intended  specifically  to  be  used  for  disinfecting, cleaning, rinsing or,  where appropriate,  hydrating contact lenses.' },
  { rule: 'Rule 17', classification_rationale: 'Rule 17 of MDR Annex VIII is covering all devices specifically  intended for  recording of  diagnostic images generated  by  X-ray  radiation.' },
  { rule: 'Rule 18', classification_rationale: 'Rule 18 of MDR Annex VIII is covering all devices manufactured utilising tissues or cells of human or animal origin, or their derivatives, which are non- viable or rendered non-viable, unless such devices are manufactured utilising tissues or cells of animal origin, or their derivatives, which are non-viable or rendered non-viable and are devices intended to come into contact with intact skin only.' },
  { rule: 'Rule 19', classification_rationale: 'Rule 19 of MDR Annex VIII is covering all devices incorporating or consisting of nanomaterial.' },
  { rule: 'Rule 20', classification_rationale: 'Rule 20 of MDR Annex VIII is covering all invasive  devices with  respect to  body orifices,  other  than  surgically invasive  devices, which are  intended  to administer medicinal products by inhalation.' },
  { rule: 'Rule 21', classification_rationale: 'Rule 21 of MDR Annex VIII is covering devices that  are  composed  of  substances or  of  combinations of  substances that  are  intended  to  be  introduced into the human body via a body orifice or applied to the skin and that are absorbed by or locally dispersed in the human body.' },
  { rule: 'Rule 22', classification_rationale: 'Rule 22 of MDR Annex VIII is covering active therapeutic devices with an integrated or incorporated diagnostic function which significantly determines the  patient management by  the  device, such  as  closed loop  systems or  automated  external defibrillators.' }
]

const isos = [
  {
    iso: 'ISO TC159',
    name: 'Ergonomics'
  },
  {
    iso: 'ISO TC194',
    name: 'Biological and clinical evaluation of medical devices'
  },
  {
    iso: 'ISO TC210',
    name: 'Quality management and corresponding general aspects for medical devices'
  },
  {
    iso: 'ISO 11137-1',
    name: 'Sterilization of health care products — Radiation — Part 1: Requirements for development, validation and routine control of a sterilization process for medical devices'
  },
  {
    iso: 'ISO 11137-2',
    name: 'Sterilization of health care products — Radiation — Part 2: Establishing the sterilization dose'
  },
  {
    iso: 'ISO 11137-3',
    name: 'Sterilization of health care products — Radiation — Part 3: Guidance on dosimetric aspects of development, validation and routine control'
  },
  {
    iso: 'ISO 11135',
    name: 'Sterilization of health-care products — Ethylene oxide — Requirements for the development, validation and routine control of a sterilization process for medical devices'
  },
  {
    iso: 'ISO 11138-1',
    name: 'Sterilization of health care products — Biological indicators — Part 1: General requirements'
  },
  {
    iso: 'ISO 11138-2',
    name: 'Sterilization of health care products — Biological indicators — Part 2: Biological indicators for ethylene oxide sterilization processes'
  },
  {
    iso: 'ISO 14161',
    name: 'Sterilization of health care products — Biological indicators — Guidance for the selection, use and interpretation of results'
  },
  {
    iso: 'ISO 18472',
    name: 'Sterilization of health care products — Biological and chemical indicators — Test equipment'
  },
  {
    iso: 'ISO 25424',
    name: 'Sterilization of health care products — Low temperature steam and formaldehyde — Requirements for development, validation and routine control of a sterilization process for medical devices'
  },
  {
    iso: 'ISO 20857',
    name: 'Sterilization of health care products — Dry heat — Requirements for the development, validation and routine control of a sterilization process for medical devices'
  },
  {
    iso: 'ISO 17665-1',
    name: 'Sterilization of health care products — Moist heat — Part 1: Requirements for the development, validation and routine control of a sterilization process for medical devices'
  },
  {
    iso: 'ISO 17665-2',
    name: 'Sterilization of health care products — Moist heat — Part 2: Guidance on the application of ISO 17665-1'
  },
  {
    iso: 'ISO 17665-3',
    name: 'Sterilization of health care products — Moist heat — Part 3: Guidance on the designation of a medical device to a product family and processing category for steam sterilization'
  },
  {
    iso: 'ISO 11737-1',
    name: 'Sterilization of health care products — Microbiological methods — Part 1: Determination of a population of microorganisms on products'
  },
  {
    iso: 'ISO 11737-2',
    name: 'Sterilization of health care products — Microbiological methods — Part 2: Tests of sterility performed in the definition, validation and maintenance of a sterilization process'
  },
  {
    iso: 'ISO 13408-1',
    name: 'Aseptic processing of health care products — Part 1: General requirements'
  },
  {
    iso: 'ISO 15883-1',
    name: 'Washer-disinfectors — Part 1: General requirements, terms and definitions and tests'
  },
  {
    iso: 'ISO 15883-5',
    name: 'Washer-disinfectors — Part 5: Test soils and methods for demonstrating cleaning efficacy'
  },
  {
    iso: 'ISO 18362',
    name: 'Manufacture of cell-based health care products — Control of microbial risks during processing'
  }
]

const materials = ['Nanomaterials', 'Metals', 'Polymers/adhesives', 'Glass']

const regions = ['USA only', 'EU only', 'Both']

const sterilizations = [{
  label: 'Radiation',
  value: JSON.stringify(['ISO 11137-1', 'ISO 11137-2', 'ISO 11137-3'])
}, {
  label: 'Ethylene Oxide',
  value: JSON.stringify(['ISO 11135', 'ISO 11138-1', 'ISO 11138-2', 'ISO 14161', 'ISO 18472'])
}, {
  label: 'Chemicals',
  value: JSON.stringify(['ISO 25424'])
}, {
  label: 'Steam',
  value: JSON.stringify(['ISO 25424'])
}, {
  label: 'Dry Heat',
  value: JSON.stringify(['ISO 20857'])
}, {
  label: 'Moist Heat',
  value: JSON.stringify(['ISO 17665-1', 'ISO 17665-2', 'ISO 17665-3'])
}, {
  label: 'Microbiologic methods',
  value: JSON.stringify(['ISO 11737-1', 'ISO 11737-2'])
}, {
  label: 'Aseptic',
  value: JSON.stringify(['ISO 13408-1'])
}, {
  label: 'Disinfectants',
  value: JSON.stringify(['ISO 15883-1', 'ISO 15883-5'])
}, {
  label: 'Cell based health care products',
  value: JSON.stringify(['ISO 18362'])
}]

export default {
  classification,
  classificationRationales,
  isos,
  materials,
  regions,
  specialRules,
  sterilizations
}

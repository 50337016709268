import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/application.messages'
import config from '@/application.config'

Vue.use(VueI18n)

export default new VueI18n({
  locale: config.application.defaultLocale,
  fallbackLocale: config.application.fallbackLocale,
  messages
})

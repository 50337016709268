const config = {
  // Allowed methods of the console object in production build
  // Set it to empty array - [] - to remove all console method calls
  allowedConsoleMethods: ['error', 'warn'],

  // Global Application configuration
  application: {
    // Name of the application
    name: 'MDR Assessment Tool',

    // Displayed icon for the application
    // Has to be a valid Material Design Icon name
    // https://materialdesignicons.com/
    icon: 'check-circle-outline',

    // UI messages are displayed in this language by default
    defaultLocale: 'en',

    // Fallback locale key, if a message does not exist in the selected locale, then it is displayed in this language
    fallbackLocale: 'en',

    // Dates are displayed in this format
    dateFormat: 'DD/MM/YYYY',

    // Dates with times are displayed in this format
    dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',

    // Dates are displayed in this local on the AppInput component - This local is needed for dd/MM/yyyy date format
    dateLocal: 'en-GB',

    // Times are displayed in this local on the AppInput component - This local is needed for h23 time format
    timeLocal: 'de'
  },

  // Authentication configuration
  authentication: {
    // These checks are performed on the client side when passwords are set (password change, password reset, etc.)
    // The errorMessage is a key in the application.messages.js file in order to provide localized messages to the users
    // Important: Keep the rules in sync with the user pool configuration of cognito!
    passwordRules: [
      {
        regex: /.{8,}/,
        message: 'login.passwordRules.lengthRule',
        errorMessage: 'login.passwordRuleLengthError'
      },
      {
        regex: /[a-z]+/,
        message: 'login.passwordRules.lowercaseRule',
        errorMessage: 'login.passwordRuleLowercaseError'
      },
      {
        regex: /[A-Z]+/,
        message: 'login.passwordRules.uppercaseRule',
        errorMessage: 'login.passwordRuleUppercaseError'
      },
      {
        regex: /[0-9]+/,
        message: 'login.passwordRules.numberRule',
        errorMessage: 'login.passwordRuleNumberError'
      },
      {
        regex: /[^A-Za-z0-9]/,
        message: 'login.passwordRules.specialCharacterRule',
        errorMessage: 'login.passwordRuleSpecialCharacterError'
      }
    ]
  },

  // AWS configuration
  aws: {
    region: 'eu-west-1',
    cognito: {
      userPoolId: 'eu-west-1_kJf1GFV4M',
      userPoolWebClientId: '2uve902eidtmm7erqnrh5ml5t'
    },
    apiGateway: {
      // url: 'http://localhost:3000/'
      url: 'https://98sp1tqh28.execute-api.eu-west-1.amazonaws.com/Prod'
    }
  }
}

// 'export default' not works with vue.config.js
module.exports = config

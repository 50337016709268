import Vue from 'vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import {
  // AlertPlugin,
  ButtonPlugin,
  CardPlugin,
  CollapsePlugin,
  FormPlugin,
  FormCheckboxPlugin,
  // FormDatepickerPlugin,
  // FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  // FormSpinbuttonPlugin,
  // FormTextareaPlugin,
  // FormTimepickerPlugin,
  InputGroupPlugin,
  LinkPlugin,
  // ListGroupPlugin,
  ModalPlugin,
  NavbarPlugin,
  ProgressPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin
  // ToastPlugin
} from 'bootstrap-vue'

// Vue.use(AlertPlugin)
Vue.use(ButtonPlugin)
Vue.use(CardPlugin)
Vue.use(CollapsePlugin)
Vue.use(FormPlugin)
Vue.use(FormCheckboxPlugin)
// Vue.use(FormDatepickerPlugin)
// Vue.use(FormFilePlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormRadioPlugin)
// Vue.use(FormSpinbuttonPlugin)
// Vue.use(FormTextareaPlugin)
// Vue.use(FormTimepickerPlugin)
Vue.use(InputGroupPlugin)
Vue.use(LinkPlugin)
// Vue.use(ListGroupPlugin)
Vue.use(ModalPlugin)
Vue.use(NavbarPlugin)
Vue.use(ProgressPlugin)
Vue.use(SpinnerPlugin)
Vue.use(TablePlugin)
Vue.use(TabsPlugin)
// Vue.use(ToastPlugin)

<template>
  <div
    id="app"
    class="d-flex flex-column h-100"
  >
    <the-navbar />
    <router-view />
  </div>
</template>

<script>
import TheNavbar from './components/TheNavbar.vue'
import { FETCH_USER } from '@/store/auth/action.types'
import { mapGetters } from 'vuex'

export default {
  components: { TheNavbar },

  computed: {
    ...mapGetters(['loggedInUser'])
  },

  watch: {
    loggedInUser (newValue) {
      if (newValue) {
        this.goTo('/classification')
      } else {
        this.goTo('/login')
      }
    }
  },

  async created () {
    await this.$store.dispatch(FETCH_USER)
  },

  methods: {
    goTo (path) {
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
    }
  }
}
</script>

import config from '@/application.config'
import data from '@/data'
import * as actionTypes from './action.types'
import { RESET_AUTH_STATE } from '@/store/auth/mutation.types'
import { RESET_HTTP_STATE } from '@/store/http/mutation.types'

const state = {
  config: config,
  classification: data.classification,
  classificationRationales: data.classificationRationales,
  inputTypes: {
    boolean: 'boolean',
    date: 'date',
    dateTime: 'dateTime',
    password: 'password',
    select: 'select',
    text: 'text'
  },
  isos: data.isos,
  materials: data.materials,
  regions: data.regions,
  specialRules: data.specialRules,
  sterilizations: data.sterilizations
}

const getters = {
  config: state => state.config,

  classification: state => state.classification,

  classificationRationales: state => state.classificationRationales,

  inputTypes: state => state.inputTypes,

  isos: state => state.isos,

  materials: state => state.materials,

  regions: state => state.regions,

  specialRules: state => state.specialRules,

  sterilizations: state => state.sterilizations
}

const actions = {
  [actionTypes.GENERATE_RANDOM_STRING]: (store, length) => [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join(''),

  [actionTypes.RESET_MODULES] ({ commit }) {
    commit(RESET_AUTH_STATE)
    commit(RESET_HTTP_STATE)
  }
}

export default {
  state,
  getters,
  actions
}

import axios from 'axios'
import qs from 'qs'
import { aws } from '@/application.config'
import { REQUEST_CANCELED } from '@/application.constants'
import * as actionTypes from './action.types'
import * as mutationTypes from './mutation.types'
import { GET_SESSION } from '../auth/action.types'
import { GENERATE_RANDOM_STRING } from '@/store/global/action.types'

const getDefaultState = () => {
  return {
    instance: axios.create({
      baseURL: aws.apiGateway.url,
      paramsSerializer: qs.stringify
    }),
    cancelTokens: {}
  }
}

const state = getDefaultState()

const actions = {

  async [actionTypes.DELETE] ({ dispatch }, { url, params, config, noAuth }) {
    return dispatch(actionTypes.DO_REQUEST, { ...config, params, url, method: 'delete', noAuth })
  },

  async [actionTypes.GET] ({ dispatch }, { url, params, config, noAuth, cancelKey }) {
    return dispatch(actionTypes.DO_REQUEST, { ...config, params, url, method: 'get', noAuth, cancelKey })
  },

  async [actionTypes.POST] ({ dispatch }, { url, data, params, config, noAuth }) {
    return dispatch(actionTypes.DO_REQUEST, { ...config, params, url, data, method: 'post', noAuth })
  },

  async [actionTypes.PUT] ({ dispatch }, { url, data, params, config, noAuth }) {
    return dispatch(actionTypes.DO_REQUEST, { ...config, params, url, data, method: 'put', noAuth })
  },

  async [actionTypes.DO_REQUEST] ({ dispatch, state }, config) {
    if (config.cancelKey) {
      const previousSource = state.cancelTokens[config.cancelKey]
      if (previousSource) {
        previousSource.cancel('There is a new request with this key: ' + config.cancelKey)
      }

      const source = axios.CancelToken.source()
      config.cancelToken = source.token
      state.cancelTokens[config.cancelKey] = source
    }

    delete config.cancelKey

    if (!config.noAuth) {
      const session = await dispatch(GET_SESSION)
      console.log(session.getIdToken().getJwtToken())

      if (!config.headers) {
        config.headers = {}
      }
      config.headers = {
        ...config.headers,
        Authorization: session.getIdToken().getJwtToken()
      }
    }

    delete config.noAuth

    const logsuffix = await dispatch(GENERATE_RANDOM_STRING, 7)
    console.time(config.method + ' - ' + config.url + ' - ' + logsuffix)

    try {
      return (await state.instance(config)).data
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled.', error.message)
        throw new Error(REQUEST_CANCELED)
      }
      console.error(error.response)
      throw error.response
    } finally {
      console.timeEnd(config.method + ' - ' + config.url + ' - ' + logsuffix)
    }
  }

}

const mutations = {
  [mutationTypes.RESET_HTTP_STATE] (state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  actions,
  mutations
}

import * as actionTypes from './action.types'
import * as mutationTypes from './mutation.types'
import Auth from '@aws-amplify/auth'
// import { MISSING_COGNITO_GROUP_MEMBERSHIP } from '@/application.constants'
import { aws, authentication } from '@/application.config'
import { RESET_MODULES } from '@/store/global/action.types'

Auth.configure({
  region: aws.region,
  userPoolId: aws.cognito.userPoolId,
  userPoolWebClientId: aws.cognito.userPoolWebClientId,
  authenticationFlowType: 'USER_PASSWORD_AUTH'
})

// const verifyGroupMembership = cognitoUser => {
//   const idToken = cognitoUser.getSignInUserSession().getIdToken()
//   if (!idToken.payload['cognito:groups'] || idToken.payload['cognito:groups'].length === 0) {
//     throw new Error(MISSING_COGNITO_GROUP_MEMBERSHIP)
//   }
// }

const getDefaultState = () => {
  return {
    loggedInUser: undefined
  }
}

const state = getDefaultState()

const getters = {
  loggedInUser: state => state.loggedInUser
}

const actions = {
  async [actionTypes.LOGIN] (context, { username, password }) {
    const cognitoUser = await Auth.signIn(username, password)

    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return cognitoUser
    }

    // verifyGroupMembership(cognitoUser)

    return cognitoUser
  },

  async [actionTypes.COMPLETE_NEW_PASSWORD] ({ dispatch }, { user, newPassword }) {
    dispatch(actionTypes.VALIDATE_PASSWORD, { password: newPassword })

    const cognitoUser = await Auth.completeNewPassword(user, newPassword)

    // verifyGroupMembership(cognitoUser)

    return cognitoUser
  },

  async [actionTypes.FORGOT_PASSWORD] (context, { username }) {
    return Auth.forgotPassword(username)
  },

  async [actionTypes.FORGOT_PASSWORD_SUBMIT] ({ dispatch }, { username, code, newPassword }) {
    dispatch(actionTypes.VALIDATE_PASSWORD, { password: newPassword })

    return Auth.forgotPasswordSubmit(username, code, newPassword)
  },

  async [actionTypes.FETCH_USER] ({ commit }) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      commit(mutationTypes.SET_LOGGED_IN_USER, user)
    } catch (err) {
      commit(mutationTypes.SET_LOGGED_IN_USER, null)
    }
  },

  async [actionTypes.LOGOUT] ({ dispatch }) {
    await Auth.signOut()
    dispatch(RESET_MODULES)
  },

  async [actionTypes.CHANGE_PASSWORD] ({ dispatch, state }, { currentPassword, newPassword }) {
    dispatch(actionTypes.VALIDATE_PASSWORD, { password: newPassword })

    return Auth.changePassword(state.loggedInUser, currentPassword, newPassword)
  },

  [actionTypes.VALIDATE_PASSWORD] (context, { password }) {
    for (const rule of authentication.passwordRules) {
      if (!rule.regex.test(password)) {
        throw Error(rule.errorMessage)
      }
    }
  },

  async [actionTypes.GET_SESSION] () {
    return Auth.currentSession()
  }

}

const mutations = {
  [mutationTypes.RESET_AUTH_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [mutationTypes.SET_LOGGED_IN_USER] (state, user) {
    state.loggedInUser = user
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

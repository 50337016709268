import Vue from 'vue'
import Vuex from 'vuex'
import globalStore from './global'
import api from './api'
import auth from './auth'
import http from './http'
import storage from './storage'

Vue.use(Vuex)

export default new Vuex.Store({
  ...globalStore,
  modules: {
    api,
    auth,
    http,
    storage
  },
  strict: process.env.NODE_ENV !== 'production'
})

import * as actionTypes from './action.types'
import { GET } from '@/store/http/action.types'

const actions = {
  async [actionTypes.GET_DOCUMENT_CONTENT] ({ dispatch }, { url }) {
    return dispatch(GET, { url, config: { responseType: 'blob' }, noAuth: true, cancelKey: actionTypes.GET_DOCUMENT_CONTENT })
  },

  async [actionTypes.GET_SIGNED_GET_URL] ({ dispatch, getters }, params) {
    return dispatch(GET, { url: ['signed-url', 'getObject'].join('/'), params })
  }
}

export default {
  actions
}
